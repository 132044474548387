import "./styles/header.css";
import logo from "../images/logo.svg";
import hamMenu from "../images/hamMenu.svg";

const Header = () => {
    return (
        <header>
            <img id="logo_image" src={logo} alt="Kutimo logo" />
            <ul>
                <li>
                    <a href="#About">About</a>
                </li>
                <li>
                    <a href="#projects">projects</a>
                </li>
                <li>
                    <a href="#Contact">Contact</a>
                </li>
            </ul>
            <img src={hamMenu} alt="Menu button" id="HamMenu" />
        </header>
    );
};

export default Header;
