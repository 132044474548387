import Header from "./Components/Header";


function App() {
    return (
        <div className="Container">
            <Header />

        </div>
    );
}

export default App;
