import "./styles/side_nav_left.css";
import linkedin from "../images/ld.svg";
import git from "../images/git.svg";
import account from "../images/account.svg";
import line from "../images/line.svg";

const SideNavLeft = () => {
    return (
        <div class="side_images">
            <a href='#'>
                <img className="side_image" src={linkedin} alt="Linkedin link" />
            </a>
            <a href='#'>
                <img className="side_image" src={git} alt="Github link" />
            </a>
            <a href='#'>
                <img className="side_image" src={account} alt="Account link" />
            </a>
            <img src={line} alt="n/a" />
        </div>
    );
};

export default SideNavLeft;;